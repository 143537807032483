<template>
  <div class="store-one-page" v-if="onePagedata">
    <!-- First bg photo -->
    <div class="relative">
      <v-img
        :src="onePagedata['media_urls']['background_image']"
        aspect-ratio="1"
        class="grey lighten-3"
      >
        <!-- lazy loading -->
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
        <!-- store intro -->
        <v-container>
          <v-row justify="center" class="text-center">
            <v-col class="store-intro pa-8" cols="12">
              <h1 class="text-md-h3 mb-3">{{ onePagedata.name }}</h1>
              <p class="font-weight-bold pa-5">
                {{ onePagedata.description }}
              </p>
              <v-btn
                outlined
                color="white"
                class="font-weight-bold"
                @click="toStoreTelegramBot(null)"
                v-if="industryType === 'retail' || !industryType"
              >
                {{ $t("__shop_immediately") }}
                <v-icon>
                  mdi-cart-arrow-right
                </v-icon>
              </v-btn>
              <v-btn
                outlined
                color="white"
                class="font-weight-bold"
                @click="toStoreTelegramBot(null)"
                v-else-if="industryType === 'catering'"
              >
                {{ $t("__catering_book_now") }}

                <v-icon>
                  mdi-silverware-fork-knife
                </v-icon>
              </v-btn>
              <v-btn
                outlined
                color="white"
                class="font-weight-bold"
                @click="toStoreTelegramBot(null)"
                v-else
              >
                {{ $t("__service_book_now") }}
                <v-icon>
                  mdi-calendar-clock
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </div>
    <v-container
      class="text-center"
      v-if="youtubeLink || onePagedata.tables.services.texts"
    >
      <!-- photo & video -->
      <section class="mb-8" v-if="youtubeLink">
        <iframe
          class="img-fluid"
          width="980"
          height="420"
          :src="youtubeLink"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </section>
      <!-- items infomation -->
      <ItemsAlbum
        v-if="
          (industryType === 'service' && onePagedata.tables.services.texts) ||
            (industryType === 'catering' && onePagedata.tables.services.texts)
        "
        :items="onePagedata.tables.services"
        @toStoreTelegramBot="toStoreTelegramBot"
      />
    </v-container>
    <!-- retail  -->
    <v-container
      v-if="industryType === 'retail' || !industryType"
      :class="{ 'px-6': !isMobile }"
    >
      <!-- product intro -->
      <RetailStoreIntro class="mb-8" @toStoreTelegramBot="toStoreTelegramBot" />
      <!-- social links &  Q&A -->
      <section class="mb-8">
        <v-row justify="center" align="center" v-if="onePagedata.links.fb">
          <v-col :cols="isMobile ? 12 : 6">
            <v-btn
              block
              dark
              elevation="0"
              color="blue darken-3"
              :href="onePagedata.links.fb"
              target="_blank"
            >
              <v-icon class="mr-2">mdi-facebook</v-icon>Facebook
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-0" v-if="onePagedata.links.ig">
          <v-col :cols="isMobile ? 12 : 6">
            <v-btn
              block
              dark
              elevation="0"
              color="pink"
              :href="onePagedata.links.ig"
              target="_blank"
            >
              <v-icon class="mr-2">mdi-instagram</v-icon>Instagram
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="onePagedata.qa">
          <v-col :cols="isMobile ? 12 : 6">
            <v-expansion-panels>
              <v-expansion-panel v-for="(item, i) in onePagedata.qa" :key="i">
                <v-expansion-panel-header class="font-weight-bold">
                  Q{{ i + 1 }} {{ item.question }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ item.answer }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </section>
      <!-- intro table -->
      <section class="mb-8" v-if="tableData[0].feature.texts">
        <h3 class="text-h4 text-center">
          {{ $t("__one_web_store_comparison_table") }}
        </h3>
        <table class="mx-auto compare-table text-left">
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("__one_web_store_comparison_table_our") }}</th>
              <th>{{ $t("__one_web_store_comparison_table_A") }}</th>
              <th>{{ $t("__one_web_store_comparison_table_B") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, i) in tableData" :key="i">
              <th>{{ data.feature.texts }}</th>
              <td class="our-style">
                <span class="d-flex justify-space-between">
                  {{ data.our.texts }}
                  <v-icon class="pl-2" color="primary" v-if="data.our.checked"
                    >mdi-check-circle</v-icon
                  >
                </span>
              </td>
              <td>
                <span class="d-flex justify-space-between">
                  {{ data.aStore.texts }}
                  <v-icon
                    class="pl-2"
                    color="primary"
                    v-if="data.aStore.checked"
                    >mdi-check-circle</v-icon
                  >
                </span>
              </td>
              <td>
                <span class="d-flex justify-space-between">
                  {{ data.bStore.texts }}
                  <v-icon
                    class="pl-2"
                    color="primary"
                    v-if="data.bStore.checked"
                    >mdi-check-circle</v-icon
                  >
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <!-- customer service -->
      <section class="mt-8">
        <v-divider />
        <h3 class="text-h4 text-center py-3">
          {{ $t("__store_info_customer_service") }}
        </h3>
        <v-divider />
        <v-row justify="center" class="px-5">
          <v-col cols="12">
            <v-list-item two-line class="text-left">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-h6">{{
                  $t("__store_info_customer_service_law")
                }}</v-list-item-title>
                <v-list-item-content
                  v-html="termsConditions"
                  class="line-height"
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="text-left">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-h6">{{
                  $t("__store_info_customer_service_return")
                }}</v-list-item-title>
                <v-list-item-content
                  v-html="returnPolicy"
                  class="line-height"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </section>
    </v-container>

    <!-- catering & service -->
    <!-- Second bg photo with store info -->
    <template
      v-else-if="industryType === 'service' || industryType === 'catering'"
    >
      <StoreIntro @toStoreTelegramBot="toStoreTelegramBot" />
      <v-container>
        <!-- store introduction -->
        <v-sheet flat class="mt-5 pa-5 rounded-lg">
          <p v-html="onePagedata.introduction" />
          <p v-if="!onePagedata.introduction">
            {{ $t("__one_web_store_introduction") }}
          </p>
        </v-sheet>
        <!-- customer service -->
        <section class="mt-8">
          <v-divider />
          <h3 class="text-h4 text-center py-3">
            {{
              industryType === "service"
                ? $t("__service_store_book_notice")
                : $t("__store_info_book_service")
            }}
          </h3>
          <v-divider />
          <v-row justify="center" :class="{ 'px-10': !isMobile }">
            <v-col cols="12">
              <v-list-item two-line class="text-left">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-h6">{{
                    industryType === "service"
                      ? $t("__service_store_book_cancel_method")
                      : $t("__store_info_hold_reservation")
                  }}</v-list-item-title>
                  <v-list-item-content
                    v-html="returnPolicy"
                    class="line-height"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line class="text-left">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-h6">{{
                    industryType === "service"
                      ? $t("__service_store_book_other_notice")
                      : $t("__store_info_cancel_reservation")
                  }}</v-list-item-title>
                  <v-list-item-content
                    v-html="termsConditions"
                    class="line-height"
                  />
                </v-list-item-content>
              </v-list-item>
              <div class="text-center" v-if="industryType !== 'service'">
                <v-btn color="primary" large @click="toStoreTelegramBot(null)"
                  >{{ $t("__catering_book_now") }}
                  <v-icon class="pl-2">mdi-silverware-fork-knife</v-icon></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </section>
        <!-- Q&A -->
        <v-row justify="center" v-if="onePagedata.qa">
          <v-col :cols="isMobile ? 12 : 6">
            <v-expansion-panels>
              <v-expansion-panel v-for="(item, i) in onePagedata.qa" :key="i">
                <v-expansion-panel-header class="font-weight-bold">
                  Q{{ i + 1 }} {{ item.question }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ item.answer }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- service project intro -->
    <v-container>
      <ProjectIntro
        v-if="industryType === 'service'"
        class="my-8"
        @toStoreTelegramBot="toStoreTelegramBot"
        @toTelegramBotCategory="toTelegramBotCategory"
      />
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import StoreIntro from "@/components/onePageWeb/StoreIntro";
import RetailStoreIntro from "@/components/sales/StoreIntro";
import ItemsAlbum from "@/components/onePageWeb/ItemsAlbum";
import ProjectIntro from "@/components/onePageWeb/ProjectIntro"

export default {
  name: "StoreOnePageWeb",
  components: {
    RetailStoreIntro,
    StoreIntro,
    ItemsAlbum,
    ProjectIntro
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      onePagedata: "store/data",
      industryType: "store/storeIndustryType"
    }),
    youtubeLink() {
      if (this.onePagedata["media_urls"]["introduction_youtube"]) {
        const link = this.onePagedata["media_urls"]["introduction_youtube"];
        const videoID = link.slice(17)
        const outputLink =` https://www.youtube.com/embed/${videoID}`
        return outputLink;
      } else {
        return null;
      }
    },
    tableData() {
      let textLength = this.onePagedata.tables.comparison.texts.length / 4;
      let comparisonTexts = this.onePagedata.tables.comparison.texts;
      let comparisonChecked = this.onePagedata.tables.comparison.checked;
      let texts = [];
      for (let i = 0; i < textLength; i++) {
        let textAry = [];
        let checkedAry = [];
        textAry = comparisonTexts.splice(0, 4);
        checkedAry = comparisonChecked.splice(0, 4);
        texts.push({
          feature: {
            texts: textAry[0],
            checked: checkedAry[0]
          },
          our: {
            texts: textAry[1],
            checked: checkedAry[1]
          },
          aStore: {
            texts: textAry[2],
            checked: checkedAry[2]
          },
          bStore: {
            texts: textAry[3],
            checked: checkedAry[3]
          }
        });
      }
      return texts;
    },
  },
  data() {
    return {
      recommendMobileClass: {
        "d-flex": this.isMobile,
        "justify-center": this.isMobile
      },
      returnPolicy:"",
      termsConditions:"",
      meta:{
        title: '',
        description: ''
      },
    };
  },
  methods: {
    loadStore() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/fetchStore", this.$route.params.id)
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
          this.$router.replace("/");
        });
    },
    toStoreTelegramBot(itemID){
      if(itemID){
      window.open(`http://t.me/${this.onePagedata.['telegram_bot_id']}?start=item_${itemID}`)
      }else{
        window.open(`http://t.me/${this.onePagedata.['telegram_bot_id']}`)
      }
    },
    toTelegramBotCategory(categoryID){
      window.open(`http://t.me/${this.onePagedata.['telegram_bot_id']}?start=category_${categoryID}`)
    }
  },
  metaInfo(){
    return {
      title: this.meta.title,
      meta:[ {
        property: 'og:title',
        content: this.meta.title,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: this.meta.description,
        vmid: 'og:description'
      },
      { name: 'description', content: this.meta.description ,vmid: 'description'},
      ]
    }
  },
  created() {
    this.loadStore()
  },
  watch: {
    isMobile: {
      immediate: true,
      handler(val) {
        if (val) {
          this.recommendMobileClass["d-flex"] = val;
          this.recommendMobileClass["justify-center"] = val;
        }
      }
    },
    onePagedata:{
      immediate: true,
      handler(val){
        if(val){
          this.returnPolicy = val['customer_service_policy']['return_policy'].split('\n').join('<br/>')
          this.termsConditions = val['customer_service_policy']['terms_and_conditions'].split('\n').join('<br/>')
          this.meta = {
            title: val.name,
            description: val.description
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.line-height {
  line-height: 1.5;
}
.store-one-page {
  background-color: #f4f4f4;
}
.store-intro {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  @media (min-width: 600px) {
    position: absolute;
    bottom: 50%;
  }
}
@media (max-width: 600px) {
  .recommend {
    flex-direction: column;
  }
}
.store-info-card {
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff;
}

.store-info {
  background-color: #f4f4f4;
}
.store-intro {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  @media (min-width: 600px) {
    position: absolute;
    bottom: 50%;
  }
}
@media (max-width: 600px) {
  .recommend {
    flex-direction: column;
  }
}
.compare-table {
  margin-top: 10px;
  border-collapse: collapse;
  @media (max-width: 600px) {
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  th,
  td {
    border: 1px solid #000;
    padding: 15px;
  }
  tr:first-child {
    border-bottom: 2px solid #000;
    th:nth-child(2) {
      background-color: orange;
      color: #fff;
      border-bottom: 2px solid #fff;
    }
  }
  .our-style {
    background-color: orange;
    color: #fff;
    border-bottom: 2px solid #fff;
  }
}
</style>
